<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>纠错信息</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="50" align="center">
				</el-table-column>
				<el-table-column label="手机号" prop="userPhone" align="center">
				</el-table-column>
				<el-table-column label="姓名" prop="userName" align="center">
				</el-table-column>
        <el-table-column label="所在单位" prop="userCompanyName" align="center">
        </el-table-column>
        <el-table-column label="vin" prop="vin" align="center">
        </el-table-column>
        <el-table-column label="备件号" prop="productCode" align="center">
        </el-table-column>
        <el-table-column label="备件名称" prop="productName" align="center">
        </el-table-column>
        <el-table-column label="纠错信息" prop="message" align="center">
        </el-table-column>
				<el-table-column prop="status" label="是否处理" width="100" align="center">
					<template slot-scope="{row}">
						<el-tag v-if="row.isSolved" type="primary" plain disable-transitions>已处理</el-tag>
						<el-tag v-else type="warning" plain disable-transitions>未处理</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="230" align="center" class-name="table-operate">
					<template slot-scope="{row}">
						<el-button v-if="!row.isSolved" class="operation-btn" size="small" title="我已处理" type="primary"
							@click="passedClick(row)">我已处理</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import http from "../../http.js";
    import power from "../../power.js"
	export default {
		components: {},
		data() {
			return {
				tableData: [],
				query: {
					keyword: '',
					page: 1,
					pagesize: 10,
          sort: '-id'
				},
				total: ''
			};
		},
		created() {
			this.getList();
		},
		methods: {
            power(p){
                return power(p)
            },
			onSubmit() {
				this.query.page = 1
				this.getList()
			},
			indexMethod(e) {
				console.log(e)
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.type == 1) {
					return "orange-row";
				}
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
				http.fetchGet("/api/admin/product/report-error-list", this.query).then((res) => {
					if (res.data.code == 0) {
						let list = res.data.data.list;
						this.tableData = list;
						this.total = res.data.data.total
					}
				});
			},
			passedClick(row) {
				this.$confirm('是否确认此信息已处理完毕?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					http.fetchPost("/api/admin/product/report-error-pass", {
						id: row.id,
					}).then((res) => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: '操作成功!'
							});
							this.getList()
						} else {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {
				});
			},
			deleteClick(row) {
				this.$confirm("确定要删除该信息吗?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						http.fetchPost("/api/admin/product/report-error-del", {
							id: row.id
						}).then((res) => {
							if (res.data.code == 0) {
								this.getList()
								this.$message({
									type: "success",
									message: "删除成功!",
								});
							}
						});
					})
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
